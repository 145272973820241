import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
/* import { GET_DASHBOARDLIST  } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:'',
      totalRecordUsers:0,
      totalRecordvehicles:0,
      totalRecorddrivers:0,
      totalRecordCompanies:0,
      totalRecordAdmin:0,
      totalRecordCoupon:0,
      totalRecordAds:0,
      totalRecordPopularcity:0,
      totalRecordMenus:0,
      totalRecordFonts:0,
      totalRecordTemplates:0,
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };

    axios.post(apiUrl+"dashboard/list",qs.stringify(postobject)).then(res => {
     
      if(res.data.status === 'success'){
        this.setState({ 
          totalRecordUsers : res.data.records_count_users,
          totalRecordCompanies : res.data.records_count_company,
          totalRecordvehicles : res.data.records_count_vehicle,
          totalRecorddrivers : res.data.records_count_drivers,
          totalRecordAdmin : res.data.records_count_admin,
          totalRecordCoupon : res.data.records_count_coupon,
          totalRecordAds : res.data.records_count_ads,
          totalRecordPopularcity : res.data.records_count_popularcity,
          totalRecordMenus : res.data.records_count_menu,
          totalRecordFonts : res.data.records_count_font,
          totalRecordTemplates : res.data.records_count_template,
          });
      }
    }); 

    }

   
    componentDidMount() {
   }




  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" currentTab="car" />
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">
                    {localStorage.getItem('admin_id') === '1' ?  <div className="form-left">
                      <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                        <div className="card-header">
                            <h4 className="card-title card-badge-red card-title-red ">{this.state.totalRecordCompanies}</h4>
                        </div>
                        <a href="/company" className="">
                          <div className="card-content collapse show">
                              <div className="card-body p-1">
                                  <h4 className="font-large-1 text-bold-400">Companies<i className="ft-briefcase float-right"></i></h4>
                              </div>
                              <div className="card-footer p-1">
                              </div>
                          </div>
                        </a>
                      </div>
                    </div>: ''}                   
                    <div className="form-right">
                      <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                        <div className="card-header">
                          <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordAdmin}</h4>
                        </div>
                        <a href="/pluginowner" className="">
                          <div className="card-content collapse show">
                              <div className="card-body p-1">
                                  <h4 className="font-large-1 text-bold-400">Admin <i className="ft-users float-right"></i></h4>
                              </div>
                              <div className="card-footer p-1">
                              </div>
                          </div>
                        </a>
                      </div>
                    </div>  
                    
                  </div> 

                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordUsers}</h4>
                          </div>
                          <a  href="/user" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecorddrivers}</h4>
                          </div>
                          <a href="/drivers" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Drivers <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>
                    <div className="form-row ">
            					<div className="form-left">            					
                        <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-yellow card-title-yellow">{this.state.totalRecordvehicles}</h4>
                            </div>
                              <a href="/vehicle" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Vehicles<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>                     
            					<div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordCoupon}</h4>
                          </div>
                          <a href="/coupon" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Coupons <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>  
            				</div>
                    

                    {/* -------------- */}
                   <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue">{this.state.totalRecordAds}</h4>
                            </div>
                              <a href="/advertisement" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Advertisements<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecordPopularcity}</h4>
                             </div>
                              <a href="/popularcity" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Popular City <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>
                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordMenus}</h4>
                          </div>
                          <a  href="/dashboardmanagement" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Menu Management <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordFonts}</h4>
                          </div>
                          <a href="/fonts" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Fonts <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>
                    <div className="form-row ">
            					<div className="form-left">
                        <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-orange">{this.state.totalRecordTemplates}</h4>
                            </div>
                              <a href="/templates" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Templates<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                    </div>

                    {/* -------------------- */}
                      
                </div>
          </div>  
      </div>
      
    </div>
    );
  }
}


export default List;