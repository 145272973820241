import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $, { parseJSON } from 'jquery';
import Select from 'react-select';

import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { mediaUrl,apiUrl} from'../Config/Config';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
  
class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		const guideId = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
		this.state = {
            guideId: guideId,			
			description:'',
			title:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			image_name: [],
			image_preview: [],
			image:[],
			old_image:[],
			image_fileExtension:[],
			allImgArray : [],
			apply_on_maintab : false,
			apply_on_innertab : false,
			old_tab_icon : '',

			user_type:'',
			user_type_value:'',
			module_type:'',
			module_type_value:'',
			selectedCateOption: '',
			selectedCatevalue:'',	
			selectedSubCateOption: '',
			selectedSubCatevalue:'',	

			ad_link:'',			
			ad_button_text:'',	

			linkType: '',			
			selectedLinkOption:"",
			selectedLinkvalue:"",
		};
		// this.props.getCategoryDetailList(guideId);
		var qs = require('qs');
		var catid = this.state.guideId;

		axios.get(apiUrl+"guide/listdetail?id="+catid).then(res => {
			
			this.setState({title:res.data.guidelist.name,description:res.data.guidelist.short_desc,tab_icon:res.data.guidelist.image});
			const formdata = res.data.guidelist;	
			if(res.data.guidelist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}			
			// if(res.data.guidelist.tab_icon !== null){
			// 	this.setState({old_tab_icon:res.data.guidelist.image});
			// }

			if(formdata.module_name){
						
				this.setState({module_type_value : formdata.module_name});
			}
			if(formdata.link_type === 'internal'){
				this.setState({
					selectedLinkvalue: formdata.link_type,
					selectedLinkOption: [{ value: formdata.link_type, label: 'Internal' }]
				});
			}else if(formdata.link_type === 'external'){
				this.setState({
					selectedLinkvalue: formdata.link_type,
					selectedLinkOption: [{ value: formdata.link_type, label: 'External' }]
				});
			}	
			if(res.data.guideimage != ''){
				
				this.setState({old_image:res.data.guideimage});
			}
			this.setState({ old_tab_icon:formdata.image,ad_link:formdata.link,ad_button_text:formdata.button_text});
			if(formdata.link){
				this.setState({linkType:'external'});
			}else{
				this.setState({linkType:'internal'});
			}			

			var postObject = {
				id: res.data.guidelist.cate_id,			
			};
			axios.post(apiUrl+"guidecategory/catedetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let catedetail = res.data.catedetail;
					this.setState({selectedCateOption:{value: catedetail['id'], label: catedetail['cate_name']}});					
				}
			});

			var postObject2 = {
				id: res.data.guidelist.sub_cate_id,			
			};
			axios.post(apiUrl+"guidesubcategory/subcatelist",qs.stringify(postObject2)).then(res => {
				if(res.data.status == "success"){					
					let subcatedetail = res.data.subcatedetail;
					this.setState({selectedSubCateOption:{value: subcatedetail['id'], label: subcatedetail['sub_cate_name']}});					
				}
			});
			
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeMultipleHandler = this.onChangeMultipleHandler.bind(this);

    }

	handleChange = (r,selOption) => {        
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}
		else if(r == 'cate'){			
			this.subCateFunc(selOption.value);
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
		}
		else if(r == 'subcate'){			
			this.setState({selectedSubCateOption:selOption})
			this.setState({  selectedSubCatevalue : selOption.value});
		}else if(r == 'inputtype'){		
			console.log(selOption,'this is linke options')
			this.setState({selectedLinkOption:selOption})
			this.setState({  selectedLinkvalue : selOption.value});
		}
    };
	subCateFunc = (cate_id=null) => {    
		var qs = require('qs'); 
		var postObject = {
			cate_id: cate_id,			
		};
		let catelists = [];
		axios.post(apiUrl+"guidesubcategory/subcatedetail",qs.stringify(postObject)).then(res => {
			
			if(res.data.status == "success"){
				let i=0;
				let catelist = res.data.catelist;
				
				for(i; i<catelist.length; i++){
					catelists[i] = { value: catelist[i]['id'], label: catelist[i]['sub_cate_name'] };
				}
				this.setState({selectedSubCateOption:catelists});	
			}      
		});
	}   
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Guide Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status, modue_typeIs, user_typeIs, cate, subcate  = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.module_type_value === '' && formPayload.module_type_value!== 0){
					modue_typeIs =formPayload.module_type.value;
					}else{
						modue_typeIs = formPayload.module_type_value;
					}
				if(formPayload.user_type_value === '' && formPayload.user_type_value!== 0){
					user_typeIs =formPayload.user_type.value;
					}else{
						user_typeIs = formPayload.user_type_value;
					}

					if(formPayload.selectedCatevalue === '' && formPayload.selectedCatevalue!== 0){
						cate =formPayload.selectedCateOption.value;
					}else{
						cate = formPayload.selectedCatevalue;
					}
				
					if(formPayload.selectedSubCatevalue === '' && formPayload.selectedSubCatevalue!== 0){
						subcate =formPayload.selectedSubCateOption.value;
					}else{
						subcate = formPayload.selectedSubCatevalue;
					}
					

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),				
					name : formPayload.title,
					description: formPayload.description,					
					status:  status,					
					id : this.state.guideId,
					// modue_typeIs      :modue_typeIs,										
					// user_typeIs      :user_typeIs,	
					ad_link:formPayload.ad_link,						
					ad_button_text:formPayload.ad_button_text,	
					modue_typeIs      :modue_typeIs,										
					link_type      :formPayload.selectedLinkvalue,		
					cate_id:cate,									
					sub_cate_id      :subcate,			
					old_tab_icon : this.state.old_tab_icon,
				
				};
				let formData = new FormData();
				
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				var imgdata = formPayload.allImgArray;
				for (var i in imgdata) {
					formData.append('images[]',imgdata[i])
				}
				var oldImgdata = (formPayload.old_image.length > 0) ? formPayload.old_image.image : '';				
				formData.append('old_images',oldImgdata)
				


				var icondata = formPayload.icon;
				formData.append('file',icondata)
				
				axios.post(apiUrl+"guide/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
							function() {
								$('.success_message').html('');
								this.props.navigate('/guide');
							}
							.bind(this),
							3000
						);						
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {title,module_type_value, selectedCateOption, selectedSubCateOption, selectedSubCatevalue}   = this.state;
		let errors = {};
      	let formIsValid = true;
		var description = this.state.description;
		
		if (!title) {
			formIsValid = false;
			$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(title){
			$('.errortab_title').html('');
		}
		// if (!module_type_value) {
		// 	errors++;
		// 	$('.errormodule').html('<span class="errorspan">Please select Module</span>');
		// }else if(module_type_value){
		// 	$('.errormodule').html('');
		// }


		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if(!selectedCateOption){
			errors++;
			$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcate').html('');
		}
	
		if(!selectedSubCatevalue){
			errors++;
			$('.errorsubcate').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorsubcate').html('');
		}
	
		this.setState({
			errors: errors
		});
		return formIsValid;
    }


	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			icon_name: file.name,
			icon_preview: reader.result,
			icon: file,
			icon_fileExtension:extension,
			
		  });
		};
		reader.readAsDataURL(file);
		
	  } 

	  onChangeMultipleHandler= e => {
		this.fileObj = [];
		this.fileNameArray = this.state.image_name;
		this.fileArray = this.state.image;
		this.extensionArray = this.state.image_fileExtension;
		this.image_preview = this.state.image_preview;
		this.allImgArray = this.state.allImgArray;
		this.fileObj.push(e.target.files);
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileNameArray.push(this.fileObj[0][i].name)
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			this.allImgArray.push(this.fileObj[0][i])
			this.extensionArray.push(this.fileObj[0][i].name.split('.').pop());
			
		}
		
		this.setState({
			image_name: this.fileNameArray,
			image_preview: this.image_preview,
			image: this.fileArray,
			image_fileExtension:this.extensionArray,
			
		  });
		
	  }
	  
	removeImagebyindex = (indexvalue) => {
		var list = this.state.image;
		var allImgArray = this.state.allImgArray;
		
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
		allImgArray.splice(indexvalue,1)

	
		this.setState({ image: list , allImgArray : allImgArray })
		
	}

	OldremoveImagebyindex = (indexvalue) => {
		var list = this.state.old_image;
		console.log(list,'list')
		list.splice(indexvalue, 1);
		console.log(list,'list')

		this.setState({ old_image: list })
		
	}

	// onEditorChange( evt ) {    
	// 	localStorage.setItem('tab_content',evt.editor.getData())
    // }

	handlefieldTypeChange(event) {   
		this.setState({module_type:[event],module_type_value:event.value})
	}
	handleadsfieldTypeChange(event) {   
		this.setState({module_type:'',module_type_value:''})
		this.setState({user_type:[event],user_type_value:event.value})
	}
  render() {

		const selectedOptiondata = lang.common.field_options.find(option => option.value === this.state.module_type_value);
  	  const {selectedOption,selectedCateOption, selectedSubCateOption, user_type_value,selectedLinkOption} = this.state;	 
		var oldImgThumblist;
		var imgThumblist ;
		let indexlength =  this.state.image.length;
		if(this.state.old_image.length > 0){
			oldImgThumblist =  this.state.old_image.map((url,index) => (
				<li className="thumb" key={indexlength+index}  >
					<span><img src={mediaUrl+'guideimages/'+url.image}  alt="..." /></span>
					<a href="javascript:void(0);" onClick={this.OldremoveImagebyindex.bind(this,index)}>X</a>
				</li>
			));
		  }  
		  
		  if(this.state.image.length > 0){
		
			imgThumblist =  this.state.image.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				));
			}
	  

		var	preview = (this.state.icon_preview) ? 
		<img className="img_class" src={this.state.icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'guideimages/'+this.state.old_tab_icon} alt="" />
		;
		

  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="guide"  currentTab="common" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/guide">Back</a>
			</div>
			<div className="title">
				<h4>Edit Guide</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedCateOption?selectedCateOption:""}
					options={lang.common.guidecategories_option} 
					onChange={this.handleChange.bind(this, 'cate')}
					/>
					<div className="errorcate"></div>
	           </div>

				{/* <div className="form-group">
					<label>User Type: </label>
					<Select 
					//value={this.state.module_type!=''?this.state.module_type:'Choose'}
					value={selectedUserOptiondata!=''?selectedUserOptiondata:'Choose'}
					options={lang.common.user_type_options} 
					onChange={this.handleadsfieldTypeChange.bind(this)}
					//placeholder= 'User Type'
					isSearchable={false}
					/>
					<div className="errorusertype"></div>
				</div> 

				<div className="form-group">
					<label>Module: </label>
					<Select 
					//value={this.state.module_type!=''?this.state.module_type:'Choose'}
					value={selectedAdsOptiondata!=''?selectedAdsOptiondata:'Choose'}
					//options={lang.common.field_options} 
					options={					
						this.state.user_type_value=='user'?lang.common.user_ads_options : 
						this.state.user_type_value=='lender'?lang.common.lender_ads_options : 
						this.state.user_type_value=='investor'?lang.common.investor_ads_options : 
						this.state.user_type_value=='broker'?lang.common.broker_ads_options : 
						this.state.user_type_value=='salesperson'?lang.common.sales_ads_options : 
						this.state.user_type_value=='company'?lang.common.company_ads_options : 
						lang.common.field_options
					}  
					onChange={this.handlefieldTypeChange.bind(this)}
					placeholder= 'Module'
					isSearchable={false}
					/>
					<div className="errormodule"></div>
				</div>  */}
				<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortab_title"></div>
				</div>

				{/* <div className="form-group">
					<label>Image (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					
					<div className="errorimage"></div>  
				</div>  */}

				{/* <div className="form-group">
					<label>Images (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="images" onChange={this.onChangeMultipleHandler} multiple/></span>
					</div>
					
					{oldImgThumblist}
					
					
					{imgThumblist}
					
					<div className="errorimage"></div>  
				</div>  */}
				<div className="form-group">
					<label>Button Text:</label>
						<input type="text" name="ad_button_text" onChange={this.handleInputChange} className="form-control" value={this.state.ad_button_text} />
					<div className="errorad_button_text"></div>
				</div>
				<div className="form-group">
					<label>Images (jpeg,jpg,png,svg)</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="images" onChange={this.onChangeMultipleHandler} multiple/></span>
					</div>
					
					<ul className='listing-thump d-flex gap-3 flex-wrap'>
					{oldImgThumblist}
					</ul>
					<ul className='listing-thump d-flex gap-3 flex-wrap'>
					{imgThumblist}
					</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorimage"></div>  
				</div> 
				
			   

			</div>
                  
			<div className="form-right">
				<div className="form-group">					
					<label>Sub Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedSubCateOption?selectedSubCateOption:""}
					options={selectedSubCateOption} 
					onChange={this.handleChange.bind(this, 'subcate')}
					/>
					<div className="errorsubcate"></div>
	           </div>
			   <div className="form-group">
					<label>Link Type</label>
					<Select
					value={selectedLinkOption?selectedLinkOption:""}
					options={lang.common.linktype} 
					onChange={this.handleChange.bind(this, 'inputtype')}
					/>
				</div>
				{this.state.selectedLinkvalue=='internal'?
				<div className="form-group">
					<label>Module: </label>
					<Select 
					//value={this.state.module_type!=''?this.state.module_type:'Choose'}
					value={selectedOptiondata!=''?selectedOptiondata:'Choose'}
					options={lang.common.field_options} 
					onChange={this.handlefieldTypeChange.bind(this)}
					placeholder= 'Module'
					isSearchable={false}
					/>
					<div className="errormodule"></div>
				</div> :''}
				{this.state.selectedLinkvalue=='external'?
				<div className="form-group">
					<label>Link Url: </label>
					<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />
					<div className="errorad_link"></div>
				</div>:''}
			   <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
					value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
					options={lang.common.status_option} 
					onChange={this.handleChange}
					/>
	           </div> 
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> : </label>
						{/* <textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  /> */}
											
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							name="description"
							// onChange={this.handleInputChange}
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({description: data});
								
							} }
						/>
					<div className="errordescription"></div>
				</div>				
			</div>	
			</div>		

			<div className='form-row'>
			
			</div>

			

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (guideId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,guideId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));
