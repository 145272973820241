import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const cateId = (typeof this.props.params.ID === 'undefined') ? '' : this.props.params.ID;
		this.state = {
            cateId: cateId,
			title:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:''
		};
		// this.props.getCategoryDetailList(cateId);
		var qs = require('qs');
		var catid = this.state.cateId;

		axios.get(apiUrl+"guidecategory/listdetail?id="+catid).then(res => {
			this.setState({title:res.data.categorylist.cate_name});
			if(res.data.categorylist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}
 	};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     		
    }


	componentDidMount() {
		document.title = PageTitle('Guide Category Edit');
    }

	
	handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}

			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				title: formPayload.title,
				status:  status,
				id: formPayload.cateId				
			};

			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}		       
			axios.post(apiUrl+"guidecategory/edit",formData ).then(res => {
				if(res.data.status === "success"){	
					this.setState({Loading:false});
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
						this.props.navigate('/guidecategory');
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
					this.setState({Loading:false});
				}
			});
		}
	}

	validateForm() {
		const {title} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		  if(!title){
			formIsValid = false;
			$('.errortitle').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errortitle').html('');
		}
			
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
  render() {

  	  const {selectedOption} = this.state;	


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="GuideCategory"  currentTab="common" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/GuideCategory">Back</a>
			</div>
			<div className="title">
				<h4>Edit Category </h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Category<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title} />
					<div className="errortitle"></div>
				</div>			
			</div>
                  
			<div className="form-right">

               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Edit));